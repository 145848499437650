import { on } from './utils/dom';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
import appWindowObject from './window';

window.appWindowObject.events.on('ui:on-scroll-visibility', (eventId, visibilityState) => {
    if (eventId !== 'scroll-up') {
        return;
    }

    if (visibilityState) {
        document.querySelector('body')?.classList.add('scroll-up--opened');
    } else {
        document.querySelector('body')?.classList.remove('scroll-up--opened');
    }
});

on('click', '[data-js-scroll-up]', () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
});
